import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
   

      <nav>
        <div className="nav-container">
          <a href="https://www.green-crypto-club.com/"><img className="nav-logo" src="/img/logo.png" alt="" /></a>
          <img className="nav-logo2" src="/img/logo2.png" alt="" />
         
        </div>
      </nav>
      <div className="content-wrapper">
          <header className="card" id="link1">
            <div style={{ padding: "0 24px 0 24px 0" }}>
             <h3>WELCOME TO THE MINTING AREA</h3>
              <h1 className="pb-3">DIGITAL VALUES FOR THE REAL WORLD!</h1>
              <h1>Are you ready to create real impact?</h1>
              <p className="text-secondary-color">
             <br></br> <span> The Green Crypto
Planet collection, powered by 2,022 Holder- and 4,044
Member-NFTs, is your chance to make a difference.<br></br>
Get yourself a ticket and be part of projects for a more
sustainable world. Together we will save our planet! </span>
              </p>
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      
                        <Minter
                          candyMachineId={candyMachineId}
                          
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                      
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </header>

         
      </div>
      <div className="social-icons">
      <a href = "https://discord.com/invite/green-crypto-club" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
          <a href = "https://twitter.com/GreenCryptoClub" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a> 
          <a href = "https://www.instagram.com/green_crypto_club/" target="_blank"><img className="nav-social" src="/icons/instagram.svg" alt="" /></a> 
          </div>
    </div>
  );
};

export default App;
